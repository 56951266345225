<template>
	<div class="el-bill-dashboard box">
		<h3><i class="lni lni-star"></i>&nbsp; Saved Bills</h3>
		<am-ul>
			<am-li v-for="b in favoriteBills" :key="b.bill_id">
				<el-bill-result :bill="b"/>
			</am-li>
		</am-ul>

		<br>
		<hr>
		<br>

		<h3 class="m0"><i class="lni lni-search"></i>&nbsp; Browse for an Interesting Bill</h3>
		<small style="color: brown" v-if="staleCongress"><i class="lni lni-warning"></i>
			&nbsp; Warning: These results are for an old version of congress.
			Click <a class="embedded" @click="updateCongress">here</a> to update.</small>
		<br><br>
		<div class="bill-filter">
			<h5>Showing Recently &nbsp;</h5>
			<select data-tooltip="?" v-model="filter.type">
				<option v-for="t in filter.types" :key="t" :value="t">{{t}}</option>
			</select>
			<h5 class="inline">&nbsp; bills in the</h5>
			<select v-model="filter.chamber">
				<option value="senate">Senate</option>
				<option value="house">House</option>
			</select>
		</div>

		<am-ul>
			<am-li v-for="b in bills" :key="b.bill_id">
				<el-bill-result :bill="b"/>
			</am-li>
		</am-ul>

	</div>
</template>

<script>
import {reactive, inject, computed} from 'vue'
import {computedAsync} from 'src/util/computed'
import {CURRENT_CONGRESS} from 'domain/Constants'
import ElBillResult from 'src/components/bill/el-bill-result.vue'
import {useStore} from 'vuex'

export default {
	name: 'el-bill-dashboard',
	components: {ElBillResult},
	setup() {
		const api = inject('api')
		const store = useStore()
		const congress = computed(() => store.state.prefs.congress || CURRENT_CONGRESS)

		function getRecentBills(chamber, type = 'active') {
			return api.proPub(`${congress.value}/${chamber}/bills/${type}`).then((r) => r.results[0].bills)
		}

		function updateCongress() {
			store.commit('prefs/setCongress', CURRENT_CONGRESS)
		}

		const filter = reactive({
			chamber: 'senate',
			type: 'updated',
			types: ['introduced', 'updated', 'active', 'passed', 'enacted', 'vetoed'],
		})

		const bills = computedAsync(() => getRecentBills(filter.chamber, filter.type))

		const favorites = computed(() => store.getters['bill/favorites'])
		const favoriteBills = computedAsync(() => api.bill.getMany(favorites.value))

		return {
			congress,
			staleCongress: computed(() => congress.value !== CURRENT_CONGRESS),
			updateCongress,
			bills,
			filter,
			favoriteBills,
		}
	}
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.bill-filter {
	flexXY(flex-start, center)
	select {
		margin-bottom: 8px
		emph()
		padding: 6px 12px
	}
}
</style>

