<template>
	<router-link :to="`/bills/${bill.bill_id}`">
		<div class="el-bill-result" :data-id="bill.bill_id">
			<div class="bill-chip">
				<h5>{{chamber}}</h5>
				<h6>No.&nbsp;{{number}}</h6>
				<small v-show="isConcurrent">Concurrent </small>
				<small v-if="isResolution">Resolution</small>
			</div>
			<div class="title">
				<h5>{{bill.short_title}}</h5>
			</div>
		</div>
	</router-link>
</template>

<script lang="ts">
import {computed, ref} from 'vue'
import {Bill} from 'domain/schema/Bill.model'
import useBillIdParser from './useBillIdParser'

export default {
	name: 'el-bill-result',
	props: {
		bill: {type: Object, required: true},
	},
	setup(props: {bill: Bill}) {
		const id = ref(props.bill.bill_id.toLowerCase())
		const {chamber, number, congress, isConcurrent, isResolution} = useBillIdParser(id)

		return {
			chamber,
			number,
			congress,
			isConcurrent,
			isResolution,
		}
	},
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-bill-result {
	flexXY(flex-start)
	.title {
		margin: 0 12px
		h5 {
			line-height: 1.35em
			text-transform: initial
		}
	}
	a {
		text-decoration: none
		color: inherit
	}
}

.bill-chip {
	min-height: 100%
	flexXY(center, flex-start)
	flex-direction: column
	padding-right: 12px
	border-right: 2px solid #ddd
	border-top-left-radius: 8px
	border-bottom-left-radius: 8px
	padding: 4px 12px
	h1, h2, h3, h4, h5, h6 {
		margin: 0
	}
}
</style>