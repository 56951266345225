
import {computed, ref} from 'vue'
import {Bill} from 'domain/schema/Bill.model'
import useBillIdParser from './useBillIdParser'

export default {
	name: 'el-bill-result',
	props: {
		bill: {type: Object, required: true},
	},
	setup(props: {bill: Bill}) {
		const id = ref(props.bill.bill_id.toLowerCase())
		const {chamber, number, congress, isConcurrent, isResolution} = useBillIdParser(id)

		return {
			chamber,
			number,
			congress,
			isConcurrent,
			isResolution,
		}
	},
}
